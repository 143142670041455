import React from 'react';
import StaticSlider from './Slider';
import style from './style.css';

const Considerations = props => {
    const { data } = props;

    const renderSlider = (number, type) => {
        if (type === 'confidence') {
            return (
                <StaticSlider
                    scale={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                    mark={number}
                    minText="Not confident at all"
                    maxText="Very Confident"
                />
            );
        }
        return (
            <StaticSlider
                scale={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                mark={number}
                minText="Not important at all"
                maxText="Very Important"
            />
        );
    };

    return (
        <>
            <hr />
            <h4 style={{ fontWeight: 'bold', marginBottom: '30px', marginTop: '40px' }}>
                {props.label}
            </h4>
            <div className={style.wrapper}>
                <div className={`row ${style.questionRow}`}>
                    <div className={`${style.questionText} col-md-6`}>
                        <span>
                            How Important is it for you to meet your goal(s) in your classroom?
                        </span>
                    </div>
                    <div className={`${style.questionText} col-md-6`}>
                        <span>
                            What is the{' '}
                            <span className={style.omit} style={{ textDecoration: 'underline' }}>
                                most
                            </span>{' '}
                            important reason for making these changes and meeting your goal(s)?
                        </span>
                    </div>
                </div>
                <div className={`row ${style.answerRow}`}>
                    <div className={`col-md-6 ${style.sliderBox}`}>
                        {renderSlider(+data.p_goals_importance1)}
                    </div>
                    <div className={`col-md-6 ${style.textBox}`}>{data.p_goals_importance2}</div>
                </div>

                <div className={`row ${style.questionRow}`}>
                    <div className={`${style.questionText} col-md-6`}>
                        <span>
                            How confident are you that you will meet your goal(s) in your classroom?
                        </span>
                    </div>
                    <div className={`${style.questionText} col-md-6`}>
                        <span>
                            What are some of the reasons that you are confident you can meet your
                            goal(s)?
                        </span>
                    </div>
                </div>
                <div className={`row ${style.answerRow}`}>
                    <div className={`col-md-6 ${style.sliderBox}`}>
                        {renderSlider(+data.p_goals_importance3, 'confidence')}
                    </div>
                    <div className={`col-md-6 ${style.textBox}`}>{data.p_goals_importance4}</div>
                </div>
                <div className={`row ${style.questionRow}`}>
                    <div className={`${style.questionText} col-md-6`}>
                        <span>
                            Is there anything that could get in the way of meeting your goal(s)?
                        </span>
                    </div>
                    <div className={`${style.questionText} col-md-6`}>
                        <span>
                            What can be done to help make sure these things do not get in the way of
                            meeting your goal(s)?
                        </span>
                    </div>
                </div>
                <div className={`row ${style.lastRow}`}>
                    <div className={`col-md-6 ${style.textBox}`}>{data.p_goals_importance5}</div>
                    <div className={`col-md-6 ${style.lastBox}`}>{data.p_goals_importance6}</div>
                </div>
            </div>
        </>
    );
};

export default Considerations;
