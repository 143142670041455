import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo, gql } from 'cccisd-apollo';
import { Report, Page, PrintOnly } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { addEvent } from 'cccisd-laravel-eventlog';
import ArrowClosed from 'cccisd-icons/arrow-right20';
import ArrowOpen from 'cccisd-icons/arrow-down20';
import Notification from 'cccisd-notification';
import PlanningSurvey from './PlanningSurvey';
import GoalItem from './GoalItem';
import Considerations from './Considerations';
import style from './style.css';
import variableQuery from './variableQuery.graphql';
import format from 'date-fns/format';

const goals = [
    {
        id: 1,
        handle: 'p_connect_curriculum',
        name: 'Connection to the Curriculum',
        variables: [
            'p_connect_curriculum_strat1',
            'p_connect_curriculum_strat2',
            'p_connect_curriculum_strat3',
            'p_connect_curriculum_strat4',
            'p_connect_curriculum_strat5',
            'p_connect_curriculum_strat0',
            'p_connect_curriculum_task',
            'p_connect_curriculum_describe',
            'p_connect_curriculum_resources',
            'p_connect_curriculum_time',
            'p_connect_curriculum_evidence',
        ],
        props: { color: '#E78C42', section: 'CARES' },
        condition: variableValues => {
            return (
                variableValues.p_connect_curriculum_strat1 === '1' ||
                variableValues.p_connect_curriculum_strat2 === '1' ||
                variableValues.p_connect_curriculum_strat3 === '1' ||
                variableValues.p_connect_curriculum_strat4 === '1' ||
                variableValues.p_connect_curriculum_strat5 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_connect_curriculum_strat1 === '1') {
                strategies.push('Cultural Relevance in Content');
            }
            if (variableValues.p_connect_curriculum_strat2 === '1') {
                strategies.push('Cooperative Learning');
            }
            if (variableValues.p_connect_curriculum_strat3 === '1') {
                strategies.push('Increasing Opportunities to Respond');
            }
            if (variableValues.p_connect_curriculum_strat4 === '1') {
                strategies.push('Interest Survey');
            }
            if (variableValues.p_connect_curriculum_strat5 === '1') {
                strategies.push('Using Group Contingencies');
            }
            return strategies;
        },
        timestamp: 'connection_goal',
        component: GoalItem,
        type: 'CARES',
        goalTag: 'ConnectionGoal',
    },
    {
        id: 2,
        handle: 'p_authentic_relations',
        name: 'Authentic Relationships',
        variables: [
            'p_authentic_relations_strat1',
            'p_authentic_relations_strat2',
            'p_authentic_relations_strat3',
            'p_authentic_relations_strat4',
            'p_authentic_relations_strat5',
            'p_authentic_relations_strat6',
            'p_authentic_relations_strat7',
            'p_authentic_relations_strat8',
            'p_authentic_relations_strat9',
            'p_authentic_relations_strat10',
            'p_authentic_relations_strat11',
            'p_authentic_relations_strat0',
            'p_authentic_relations_task',
            'p_authentic_relations_describe',
            'p_authentic_relations_resources',
            'p_authentic_relations_time',
            'p_authentic_relations_evidence',
        ],
        props: { color: '#E78C42', section: 'CARES' },
        condition: variableValues => {
            return (
                variableValues.p_authentic_relations_strat1 === '1' ||
                variableValues.p_authentic_relations_strat2 === '1' ||
                variableValues.p_authentic_relations_strat3 === '1' ||
                variableValues.p_authentic_relations_strat4 === '1' ||
                variableValues.p_authentic_relations_strat5 === '1' ||
                variableValues.p_authentic_relations_strat6 === '1' ||
                variableValues.p_authentic_relations_strat7 === '1' ||
                variableValues.p_authentic_relations_strat8 === '1' ||
                variableValues.p_authentic_relations_strat9 === '1' ||
                variableValues.p_authentic_relations_strat10 === '1' ||
                variableValues.p_authentic_relations_strat11 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_authentic_relations_strat1 === '1') {
                strategies.push('Interest Survey');
            }
            if (variableValues.p_authentic_relations_strat2 === '1') {
                strategies.push('Using Journals');
            }
            if (variableValues.p_authentic_relations_strat3 === '1') {
                strategies.push('Charting Tool');
            }
            if (variableValues.p_authentic_relations_strat4 === '1') {
                strategies.push('Building a Classroom Community');
            }
            if (variableValues.p_authentic_relations_strat5 === '1') {
                strategies.push('Cooperative Learning');
            }
            if (variableValues.p_authentic_relations_strat6 === '1') {
                strategies.push('Greeting Students at the Door');
            }
            if (variableValues.p_authentic_relations_strat7 === '1') {
                strategies.push('Identifying Reinforcers');
            }
            if (variableValues.p_authentic_relations_strat8 === '1') {
                strategies.push('Teaching Behavior Expectations');
            }
            if (variableValues.p_authentic_relations_strat9 === '1') {
                strategies.push('Using Precorrection');
            }
            if (variableValues.p_authentic_relations_strat10 === '1') {
                strategies.push('Using Behavior-specific Praise');
            }
            if (variableValues.p_authentic_relations_strat11 === '1') {
                strategies.push('Using Social and Emotional Coaching');
            }
            return strategies;
        },
        component: GoalItem,
        timestamp: 'authentic_relationships_goal',
        type: 'CARES',
        goalTag: 'AuthenticRelationshipsGoal',
    },
    {
        id: 3,
        handle: 'p_reflective_thinking',
        name: 'Reflective Thinking',
        variables: [
            'p_reflective_thinking_strat1',
            'p_reflective_thinking_strat2',
            'p_reflective_thinking_strat3',
            'p_reflective_thinking_strat4',
            'p_reflective_thinking_strat5',
            'p_reflective_thinking_strat6',
            'p_reflective_thinking_strat7',
            'p_reflective_thinking_strat8',
            'p_reflective_thinking_strat9',
            'p_reflective_thinking_strat10',
            'p_reflective_thinking_strat11',
            'p_reflective_thinking_strat0',
            'p_reflective_thinking_task',
            'p_reflective_thinking_describe',
            'p_reflective_thinking_resources',
            'p_reflective_thinking_time',
            'p_reflective_thinking_evidence',
        ],
        props: { color: '#E78C42', section: 'CARES' },
        condition: variableValues => {
            return (
                variableValues.p_reflective_thinking_strat1 === '1' ||
                variableValues.p_reflective_thinking_strat2 === '1' ||
                variableValues.p_reflective_thinking_strat3 === '1' ||
                variableValues.p_reflective_thinking_strat4 === '1' ||
                variableValues.p_reflective_thinking_strat5 === '1' ||
                variableValues.p_reflective_thinking_strat6 === '1' ||
                variableValues.p_reflective_thinking_strat7 === '1' ||
                variableValues.p_reflective_thinking_strat8 === '1' ||
                variableValues.p_reflective_thinking_strat9 === '1' ||
                variableValues.p_reflective_thinking_strat10 === '1' ||
                variableValues.p_reflective_thinking_strat11 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_reflective_thinking_strat1 === '1') {
                strategies.push('Avoiding Power Struggles');
            }
            if (variableValues.p_reflective_thinking_strat2 === '1') {
                strategies.push('Charting Tool');
            }
            if (variableValues.p_reflective_thinking_strat3 === '1') {
                strategies.push('Using Social and Emotional Coaching');
            }
            if (variableValues.p_reflective_thinking_strat4 === '1') {
                strategies.push('Code Switching');
            }
            if (variableValues.p_reflective_thinking_strat5 === '1') {
                strategies.push('Cultural Relevance in Content');
            }
            if (variableValues.p_reflective_thinking_strat6 === '1') {
                strategies.push('Culturally Responsive FBT');
            }
            if (variableValues.p_reflective_thinking_strat7 === '1') {
                strategies.push('Increasing Opportunities to Respond');
            }
            if (variableValues.p_reflective_thinking_strat8 === '1') {
                strategies.push('Interest Survey');
            }
            if (variableValues.p_reflective_thinking_strat9 === '1') {
                strategies.push('Using Behavior-specific Praise');
            }
            if (variableValues.p_reflective_thinking_strat10 === '1') {
                strategies.push('Using Group Contingencies');
            }
            if (variableValues.p_reflective_thinking_strat11 === '1') {
                strategies.push('Using Journals');
            }
            return strategies;
        },
        component: GoalItem,
        timestamp: 'reflective_thinking_goal',
        type: 'CARES',
        goalTag: 'ReflectiveThinkingGoal',
    },
    {
        id: 4,
        handle: 'p_effective_comm',
        name: 'Effective Communication',
        variables: [
            'p_effective_comm_strat1',
            'p_effective_comm_strat2',
            'p_effective_comm_strat3',
            'p_effective_comm_strat4',
            'p_effective_comm_strat5',
            'p_effective_comm_strat6',
            'p_effective_comm_strat7',
            'p_effective_comm_strat8',
            'p_effective_comm_strat9',
            'p_effective_comm_strat10',
            'p_effective_comm_strat11',
            'p_effective_comm_strat12',
            'p_effective_comm_strat13',
            'p_effective_comm_strat14',
            'p_effective_comm_strat0',
            'p_effective_comm_task',
            'p_effective_comm_describe',
            'p_effective_comm_resources',
            'p_effective_comm_time',
            'p_effective_comm_evidence',
        ],
        props: { color: '#E78C42', section: 'CARES' },
        condition: variableValues => {
            return (
                variableValues.p_effective_comm_strat1 === '1' ||
                variableValues.p_effective_comm_strat2 === '1' ||
                variableValues.p_effective_comm_strat3 === '1' ||
                variableValues.p_effective_comm_strat4 === '1' ||
                variableValues.p_effective_comm_strat5 === '1' ||
                variableValues.p_effective_comm_strat6 === '1' ||
                variableValues.p_effective_comm_strat7 === '1' ||
                variableValues.p_effective_comm_strat8 === '1' ||
                variableValues.p_effective_comm_strat9 === '1' ||
                variableValues.p_effective_comm_strat10 === '1' ||
                variableValues.p_effective_comm_strat11 === '1' ||
                variableValues.p_effective_comm_strat12 === '1' ||
                variableValues.p_effective_comm_strat13 === '1' ||
                variableValues.p_effective_comm_strat14 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_effective_comm_strat1 === '1') {
                strategies.push('Code Switching');
            }
            if (variableValues.p_effective_comm_strat2 === '1') {
                strategies.push('Defining and Teaching Classroom Rules');
            }
            if (variableValues.p_effective_comm_strat3 === '1') {
                strategies.push('Using Behavior-specific Praise');
            }
            if (variableValues.p_effective_comm_strat4 === '1') {
                strategies.push('Avoiding Power Struggles');
            }
            if (variableValues.p_effective_comm_strat5 === '1') {
                strategies.push('Charting Tool');
            }
            if (variableValues.p_effective_comm_strat6 === '1') {
                strategies.push('Developing and Using Clear Academic Objectives');
            }
            if (variableValues.p_effective_comm_strat7 === '1') {
                strategies.push('Greeting Students at the Door');
            }
            if (variableValues.p_effective_comm_strat8 === '1') {
                strategies.push('Interest Survey');
            }
            if (variableValues.p_effective_comm_strat9 === '1') {
                strategies.push('Posting and Using a Schedule');
            }
            if (variableValues.p_effective_comm_strat10 === '1') {
                strategies.push('Providing Academic Feedback');
            }
            if (variableValues.p_effective_comm_strat11 === '1') {
                strategies.push('Teaching Behavior Expectations');
            }
            if (variableValues.p_effective_comm_strat12 === '1') {
                strategies.push('Using Precorrection');
            }
            if (variableValues.p_effective_comm_strat13 === '1') {
                strategies.push('Using an Attention Signal');
            }
            if (variableValues.p_effective_comm_strat14 === '1') {
                strategies.push('Using Planned Ignoring');
            }
            return strategies;
        },
        component: GoalItem,
        timestamp: 'effective_communication_goal',
        type: 'CARES',
        goalTag: 'EffectiveCommunicationGoal',
    },
    {
        id: 5,
        handle: 'p_sensitive_stud_cult',
        name: "Sensitivity to Students' Culture",
        variables: [
            'p_sensitive_stud_cult_strat1',
            'p_sensitive_stud_cult_strat2',
            'p_sensitive_stud_cult_strat3',
            'p_sensitive_stud_cult_strat4',
            'p_sensitive_stud_cult_strat5',
            'p_sensitive_stud_cult_strat6',
            'p_sensitive_stud_cult_strat7',
            'p_sensitive_stud_cult_strat8',
            'p_sensitive_stud_cult_strat9',
            'p_sensitive_stud_cult_strat10',
            'p_sensitive_stud_cult_strat11',
            'p_sensitive_stud_cult_strat12',
            'p_sensitive_stud_cult_strat13',
            'p_sensitive_stud_cult_strat14',
            'p_sensitive_stud_cult_strat15',
            'p_sensitive_stud_cult_strat16',
            'p_sensitive_stud_cult_strat17',
            'p_sensitive_stud_cult_strat0',
            'p_sensitive_stud_cult_task',
            'p_sensitive_stud_cult_describe',
            'p_sensitive_stud_cult_resources',
            'p_sensitive_stud_cult_time',
            'p_sensitive_stud_cult_evidence',
        ],
        props: { color: '#E78C42', section: 'CARES' },
        condition: variableValues => {
            return (
                variableValues.p_sensitive_stud_cult_strat1 === '1' ||
                variableValues.p_sensitive_stud_cult_strat2 === '1' ||
                variableValues.p_sensitive_stud_cult_strat3 === '1' ||
                variableValues.p_sensitive_stud_cult_strat4 === '1' ||
                variableValues.p_sensitive_stud_cult_strat5 === '1' ||
                variableValues.p_sensitive_stud_cult_strat6 === '1' ||
                variableValues.p_sensitive_stud_cult_strat7 === '1' ||
                variableValues.p_sensitive_stud_cult_strat8 === '1' ||
                variableValues.p_sensitive_stud_cult_strat9 === '1' ||
                variableValues.p_sensitive_stud_cult_strat10 === '1' ||
                variableValues.p_sensitive_stud_cult_strat11 === '1' ||
                variableValues.p_sensitive_stud_cult_strat12 === '1' ||
                variableValues.p_sensitive_stud_cult_strat13 === '1' ||
                variableValues.p_sensitive_stud_cult_strat14 === '1' ||
                variableValues.p_sensitive_stud_cult_strat15 === '1' ||
                variableValues.p_sensitive_stud_cult_strat16 === '1' ||
                variableValues.p_sensitive_stud_cult_strat17 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_sensitive_stud_cult_strat1 === '1') {
                strategies.push('Building a Classroom Community');
            }
            if (variableValues.p_sensitive_stud_cult_strat2 === '1') {
                strategies.push('Providing Academic Feedback');
            }
            if (variableValues.p_sensitive_stud_cult_strat3 === '1') {
                strategies.push('Teaching Behavior Expectations');
            }
            if (variableValues.p_sensitive_stud_cult_strat4 === '1') {
                strategies.push('Avoiding Power Struggles');
            }
            if (variableValues.p_sensitive_stud_cult_strat5 === '1') {
                strategies.push('Charting Tool');
            }
            if (variableValues.p_sensitive_stud_cult_strat6 === '1') {
                strategies.push('Code Switching');
            }
            if (variableValues.p_sensitive_stud_cult_strat7 === '1') {
                strategies.push('Cooperative Learning');
            }
            if (variableValues.p_sensitive_stud_cult_strat8 === '1') {
                strategies.push('Cultural Relevance in Content');
            }
            if (variableValues.p_sensitive_stud_cult_strat9 === '1') {
                strategies.push('Culturally Responsive FBT');
            }
            if (variableValues.p_sensitive_stud_cult_strat10 === '1') {
                strategies.push('Identifying Reinforcers');
            }
            if (variableValues.p_sensitive_stud_cult_strat11 === '1') {
                strategies.push('Interest Survey');
            }
            if (variableValues.p_sensitive_stud_cult_strat12 === '1') {
                strategies.push('Posting and Using a Schedule');
            }
            if (variableValues.p_sensitive_stud_cult_strat13 === '1') {
                strategies.push('Using Active Supervision');
            }
            if (variableValues.p_sensitive_stud_cult_strat14 === '1') {
                strategies.push('Using Group Contingencies');
            }
            if (variableValues.p_sensitive_stud_cult_strat15 === '1') {
                strategies.push('Using Journals');
            }
            if (variableValues.p_sensitive_stud_cult_strat16 === '1') {
                strategies.push('Using Planned Ignoring');
            }
            if (variableValues.p_sensitive_stud_cult_strat17 === '1') {
                strategies.push('Using Social and Emotional Coaching');
            }
            return strategies;
        },
        component: GoalItem,
        timestamp: 'sensitivity_goal',
        type: 'CARES',
        goalTag: 'SensitivityGoal',
    },
    {
        id: 5,
        handle: 'p_stud_engage',
        name: 'Student Engagement',
        variables: [
            'p_stud_engage_strat1',
            'p_stud_engage_strat2',
            'p_stud_engage_strat3',
            'p_stud_engage_strat4',
            'p_stud_engage_strat5',
            'p_stud_engage_strat6',
            'p_stud_engage_strat0',
            'p_stud_engage_task',
            'p_stud_engage_describe',
            'p_stud_engage_resources',
            'p_stud_engage_time',
            'p_stud_engage_evidence',
        ],
        props: { color: '#8B76BE', section: 'Positive Behavior Supports & Classroom Climate' },
        condition: variableValues => {
            return (
                variableValues.p_stud_engage_strat1 === '1' ||
                variableValues.p_stud_engage_strat2 === '1' ||
                variableValues.p_stud_engage_strat3 === '1' ||
                variableValues.p_stud_engage_strat4 === '1' ||
                variableValues.p_stud_engage_strat5 === '1' ||
                variableValues.p_stud_engage_strat6 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_stud_engage_strat1 === '1') {
                strategies.push('Developing and Using Clear Academic Objectives');
            }
            if (variableValues.p_stud_engage_strat2 === '1') {
                strategies.push('Increasing Opportunities to Respond');
            }
            if (variableValues.p_stud_engage_strat3 === '1') {
                strategies.push('Providing Academic Feedback');
            }
            if (variableValues.p_stud_engage_strat4 === '1') {
                strategies.push('Building a Classroom Community');
            }
            if (variableValues.p_stud_engage_strat5 === '1') {
                strategies.push('Cooperative Learning');
            }
            if (variableValues.p_stud_engage_strat6 === '1') {
                strategies.push('Cultural Relevance in Content');
            }

            return strategies;
        },
        component: GoalItem,
        timestamp: 'student_engagement_goal',
        type: 'PBSCC',
        goalTag: 'StudentEngagementGoal',
    },
    {
        id: 6,
        handle: 'p_disruptive_behave',
        name: 'Level of Disruptive Behavior',
        variables: [
            'p_disruptive_behave_strat1',
            'p_disruptive_behave_strat2',
            'p_disruptive_behave_strat3',
            'p_disruptive_behave_strat4',
            'p_disruptive_behave_strat5',
            'p_disruptive_behave_strat6',
            'p_disruptive_behave_strat7',
            'p_disruptive_behave_strat8',
            'p_disruptive_behave_strat9',
            'p_disruptive_behave_strat10',
            'p_disruptive_behave_strat11',
            'p_disruptive_behave_strat12',
            'p_disruptive_behave_strat0',
            'p_disruptive_behave_task',
            'p_disruptive_behave_describe',
            'p_disruptive_behave_resources',
            'p_disruptive_behave_time',
            'p_disruptive_behave_evidence',
        ],
        props: { color: '#8B76BE', section: 'Positive Behavior Supports & Classroom Climate' },
        condition: variableValues => {
            return (
                variableValues.p_disruptive_behave_strat1 === '1' ||
                variableValues.p_disruptive_behave_strat2 === '1' ||
                variableValues.p_disruptive_behave_strat3 === '1' ||
                variableValues.p_disruptive_behave_strat4 === '1' ||
                variableValues.p_disruptive_behave_strat5 === '1' ||
                variableValues.p_disruptive_behave_strat6 === '1' ||
                variableValues.p_disruptive_behave_strat7 === '1' ||
                variableValues.p_disruptive_behave_strat8 === '1' ||
                variableValues.p_disruptive_behave_strat9 === '1' ||
                variableValues.p_disruptive_behave_strat10 === '1' ||
                variableValues.p_disruptive_behave_strat11 === '1' ||
                variableValues.p_disruptive_behave_strat12 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_disruptive_behave_strat1 === '1') {
                strategies.push('Defining and Teaching Classroom Rules');
            }
            if (variableValues.p_disruptive_behave_strat2 === '1') {
                strategies.push('Teaching Behavior Expectations');
            }
            if (variableValues.p_disruptive_behave_strat3 === '1') {
                strategies.push('Using Active Supervision');
            }
            if (variableValues.p_disruptive_behave_strat4 === '1') {
                strategies.push('Using Behavior-specific Praise');
            }
            if (variableValues.p_disruptive_behave_strat5 === '1') {
                strategies.push('Using Group Contingencies');
            }
            if (variableValues.p_disruptive_behave_strat6 === '1') {
                strategies.push('Using Planned Ignoring');
            }
            if (variableValues.p_disruptive_behave_strat7 === '1') {
                strategies.push('Using Precorrection');
            }
            if (variableValues.p_disruptive_behave_strat8 === '1') {
                strategies.push('Cultural Relevance in Content');
            }
            if (variableValues.p_disruptive_behave_strat9 === '1') {
                strategies.push('Developing and Using Clear Academic Objectives');
            }
            if (variableValues.p_disruptive_behave_strat10 === '1') {
                strategies.push('Identifying Reinforcers');
            }
            if (variableValues.p_disruptive_behave_strat11 === '1') {
                strategies.push('Increasing Opportunities to Respond');
            }
            if (variableValues.p_disruptive_behave_strat12 === '1') {
                strategies.push('Using an Attention Signal');
            }

            return strategies;
        },
        component: GoalItem,
        timestamp: 'disruptive_behavior_goal',
        type: 'PBSCC',
        goalTag: 'DisruptiveBehaviorGoal',
    },
    {
        id: 7,
        handle: 'p_pacing',
        name: 'Pacing of Instruction',
        variables: [
            'p_pacing_strat',
            'p_pacing_task',
            'p_pacing_describe',
            'p_pacing_resources',
            'p_pacing_time',
            'p_pacing_evidence',
        ],
        props: { color: '#8B76BE', section: 'Positive Behavior Supports & Classroom Climate' },
        condition: variableValues => {
            return variableValues.p_pacing_strat === '1';
        },
        strategy: () => {
            return ['Increasing Opportunities to Respond'];
        },
        component: GoalItem,
        timestamp: 'pacing_instruction_goal',
        type: 'PBSCC',
        goalTag: 'PacingInstructionGoal',
    },
    {
        id: 8,
        handle: 'p_clear_expect',
        name: 'Clear Expectations',
        variables: [
            'p_clear_expect_strat1',
            'p_clear_expect_strat2',
            'p_clear_expect_strat3',
            'p_clear_expect_strat4',
            'p_clear_expect_strat5',
            'p_clear_expect_strat0',
            'p_clear_expect_task',
            'p_clear_expect_describe',
            'p_clear_expect_resources',
            'p_clear_expect_time',
            'p_clear_expect_evidence',
        ],
        props: { color: '#8B76BE', section: 'Positive Behavior Supports & Classroom Climate' },
        condition: variableValues => {
            return (
                variableValues.p_clear_expect_strat1 === '1' ||
                variableValues.p_clear_expect_strat2 === '1' ||
                variableValues.p_clear_expect_strat3 === '1' ||
                variableValues.p_clear_expect_strat4 === '1' ||
                variableValues.p_clear_expect_strat5 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_clear_expect_strat1 === '1') {
                strategies.push('Teaching Behavior Expectations');
            }
            if (variableValues.p_clear_expect_strat2 === '1') {
                strategies.push('Using Precorrection');
            }
            if (variableValues.p_clear_expect_strat3 === '1') {
                strategies.push('Using Group Contingencies');
            }
            if (variableValues.p_clear_expect_strat4 === '1') {
                strategies.push('Defining and Teaching Classroom Rules');
            }
            if (variableValues.p_clear_expect_strat5 === '1') {
                strategies.push('Developing and Using Clear Academic Objectives');
            }

            return strategies;
        },
        component: GoalItem,
        timestamp: 'clear_expectations_goal',
        type: 'PBSCC',
        goalTag: 'ClearExpectationsGoal',
    },
    {
        id: 9,
        handle: 'p_use_praise',
        name: 'Use of Praise',
        variables: [
            'p_use_praise_strat1',
            'p_use_praise_strat2',
            'p_use_praise_strat3',
            'p_use_praise_strat4',
            'p_use_praise_strat5',
            'p_use_praise_strat6',
            'p_use_praise_strat7',
            'p_use_praise_strat8',
            'p_use_praise_strat0',
            'p_use_praise_task',
            'p_use_praise_describe',
            'p_use_praise_resources',
            'p_use_praise_time',
            'p_use_praise_evidence',
        ],
        props: { color: '#8B76BE', section: 'Positive Behavior Supports & Classroom Climate' },
        condition: variableValues => {
            return (
                variableValues.p_use_praise_strat1 === '1' ||
                variableValues.p_use_praise_strat2 === '1' ||
                variableValues.p_use_praise_strat3 === '1' ||
                variableValues.p_use_praise_strat4 === '1' ||
                variableValues.p_use_praise_strat5 === '1' ||
                variableValues.p_use_praise_strat6 === '1' ||
                variableValues.p_use_praise_strat7 === '1' ||
                variableValues.p_use_praise_strat8 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_use_praise_strat1 === '1') {
                strategies.push('Using Behavior-specific Praise');
            }
            if (variableValues.p_use_praise_strat2 === '1') {
                strategies.push('Using Social and Emotional Coaching');
            }
            if (variableValues.p_use_praise_strat3 === '1') {
                strategies.push('Using Active Supervision');
            }
            if (variableValues.p_use_praise_strat4 === '1') {
                strategies.push('Using Group Contingencies');
            }
            if (variableValues.p_use_praise_strat5 === '1') {
                strategies.push('Using Planned Ignoring');
            }
            if (variableValues.p_use_praise_strat6 === '1') {
                strategies.push('Identifying Reinforcers');
            }
            if (variableValues.p_use_praise_strat7 === '1') {
                strategies.push('Teaching Behavior Expectations');
            }
            if (variableValues.p_use_praise_strat8 === '1') {
                strategies.push('Using Precorrection');
            }
            return strategies;
        },
        component: GoalItem,
        timestamp: 'praise_goal',
        type: 'PBSCC',
        goalTag: 'PraiseGoal',
    },
    {
        id: 10,
        handle: 'p_use_reprimands',
        name: 'Use of Reprimands',
        variables: [
            'p_use_reprimands_strat1',
            'p_use_reprimands_strat2',
            'p_use_reprimands_strat3',
            'p_use_reprimands_strat4',
            'p_use_reprimands_strat0',
            'p_use_reprimands_task',
            'p_use_reprimands_describe',
            'p_use_reprimands_resources',
            'p_use_reprimands_time',
            'p_use_reprimands_evidence',
        ],
        props: { color: '#8B76BE', section: 'Positive Behavior Supports & Classroom Climate' },
        condition: variableValues => {
            return (
                variableValues.p_use_reprimands_strat1 === '1' ||
                variableValues.p_use_reprimands_strat2 === '1' ||
                variableValues.p_use_reprimands_strat3 === '1' ||
                variableValues.p_use_reprimands_strat4 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_use_reprimands_strat1 === '1') {
                strategies.push('Teaching Behavior Expectations');
            }
            if (variableValues.p_use_reprimands_strat2 === '1') {
                strategies.push('Using Group Contingencies');
            }
            if (variableValues.p_use_reprimands_strat3 === '1') {
                strategies.push('Using Precorrection');
            }
            if (variableValues.p_use_reprimands_strat4 === '1') {
                strategies.push('Using Active Supervision');
            }

            return strategies;
        },
        component: GoalItem,
        timestamp: 'reprimands_goal',
        type: 'PBSCC',
        goalTag: 'ReprimandsGoal',
    },
    {
        id: 11,
        handle: 'p_smooth_trans',
        name: 'Smooth Transitions',
        variables: [
            'p_smooth_trans_strat1',
            'p_smooth_trans_strat2',
            'p_smooth_trans_strat3',
            'p_smooth_trans_strat0',
            'p_smooth_trans_task',
            'p_smooth_trans_describe',
            'p_smooth_trans_resources',
            'p_smooth_trans_time',
            'p_smooth_trans_evidence',
        ],
        props: { color: '#8B76BE', section: 'Positive Behavior Supports & Classroom Climate' },
        condition: variableValues => {
            return (
                variableValues.p_smooth_trans_strat1 === '1' ||
                variableValues.p_smooth_trans_strat2 === '1' ||
                variableValues.p_smooth_trans_strat3 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_smooth_trans_strat1 === '1') {
                strategies.push('Using an Attention Signal');
            }
            if (variableValues.p_smooth_trans_strat2 === '1') {
                strategies.push('Posting and Using a Schedule');
            }
            if (variableValues.p_smooth_trans_strat3 === '1') {
                strategies.push('Teaching Classroom Routines');
            }
            return strategies;
        },
        component: GoalItem,
        timestamp: 'smooth_transitions_goal',
        type: 'PBSCC',
        goalTag: 'SmoothTransitionsGoal',
    },
    {
        id: 12,
        handle: 'p_other_pbscc',
        name: 'Other PCBS & CC',
        variables: [
            'p_other_pbscc_strat1',
            'p_other_pbscc_strat2a',
            'p_other_pbscc_strat2b',
            'p_other_pbscc_task',
            'p_other_pbscc_describe',
            'p_other_pbscc_resources',
            'p_other_pbscc_time',
            'p_other_pbscc_evidence',
        ],
        props: { color: '#8B76BE', section: 'Positive Behavior Supports & Classroom Climate' },
        condition: variableValues => {
            return (
                variableValues.p_other_pbscc_strat1 && variableValues.p_other_pbscc_strat1 !== '0'
            );
        },
        strategy: variableValues => {
            const options = {
                '1': 'Defining and Teaching Classroom Rules',
                '2': 'Developing and Using Clear Academic Objectives',
                '3': 'Increasing Opportunities to Respond',
                '4': 'Posting and Using a Schedule',
                '5': 'Providing Academic Feedback',
                '6': 'Teaching Behavior Expectations',
                '7': 'Teaching Classroom Routines',
                '8': 'Using Active Supervision',
                '9': 'Using an Attention Signal',
                '10': 'Using Behavior-specific Praise',
                '11': 'Using Group Contingencies',
                '12': 'Using Planned Ignoring',
                '13': 'Using Precorrection',
                '14': 'Using Social and Emotional Coaching ',
                '15': 'Building a Classroom Community',
                '16': 'Cooperative Learning',
                '17': 'Cultural Relevance in Content',
                '18': 'Identifying Reinforcers',
                '0': 'None (at this time)',
            };
            let firstStrat;

            let secondStrat;

            firstStrat = options[variableValues.p_other_pbscc_strat1];

            if (firstStrat) {
                if (variableValues.p_other_pbscc_strat2a === '1') {
                    secondStrat = options[variableValues.p_other_pbscc_strat2b];
                }
                if (secondStrat) {
                    return [firstStrat, secondStrat];
                }
                return [firstStrat];
            }
            return [];
        },
        component: GoalItem,
        timestamp: 'pbscc_other_goal',
        type: 'PBSCC',
        goalTag: 'PBSCCOtherGoal',
    },
    {
        id: 13,
        handle: 'Considerations',
        name: 'When considering your goal(s):',
        variables: [
            'p_goals_importance1',
            'p_goals_importance2',
            'p_goals_importance3',
            'p_goals_importance4',
            'p_goals_importance5',
            'p_goals_importance6',
        ],
        condition: () => {
            return true;
        },
        component: Considerations,
    },
];

const InterventionPlanning = props => {
    const { selectedPerson } = props;
    const [report, setReport] = useState(null);
    const [data, setData] = useState(null);
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [requiredTypes, setRequiredTypes] = useState(false);
    const [completedBy, setCompletedBy] = useState(null);

    useEffect(() => {
        const allVariables = getGoals();

        async function getData() {
            const res = await fetchData(allVariables);

            const values = res ? res.data.roles.anyRole.planningMetrics.variables : null;
            const stat = res ? res.data.roles.anyRole.planningProgress[0] : null;
            const selectedGoals = [];
            const completer = res ? res.data.roles.anyRole.targetEvent.pawn.user.fullName : '';

            for (const [key, value] of Object.entries(props.goals)) {
                if (value === true) {
                    selectedGoals.push(key);
                }
            }

            const typesSelected = goals
                .filter(item => selectedGoals.includes(item.goalTag))
                .map(item => item.type);

            if (typesSelected.includes('CARES') && typesSelected.includes('PBSCC')) {
                setRequiredTypes(true);
            }

            if (stat && stat.completed) {
                setData(values);
                setCompletedBy(completer);
            }

            setStatus(stat);
            setLoading(false);
        }

        getData();
    }, [data]);

    const fetchData = async allVariables => {
        let results = null;
        if (selectedPerson.deploymentId) {
            results = await apollo.query({
                query: gql`
                    ${variableQuery}
                `,
                fetchPolicy: 'network-only',
                variables: {
                    pawnId: selectedPerson.value,
                    deploymentId: selectedPerson.deploymentId,
                    assignmentId: selectedPerson.assignmentId,
                    variableList: allVariables,
                },
            });
        }

        return results;
    };

    const filteredGoals = data ? goals.filter(goal => goal.condition(data)) : null;

    const download = () => {
        openAll();
        setTimeout(async () => {
            Notification({
                message: 'Please wait a moment while your PDF is prepared',
                type: 'info',
                duration: 5000,
            });
            await report.download();
            closeAll();
        }, 1000);
    };

    const getGoals = () => {
        const allVariables = [];

        goals.forEach(goal => {
            for (const variable of goal.variables) {
                allVariables.push(variable);
            }
        });
        return allVariables;
    };

    const reloadData = async () => {
        const allVariables = getGoals();
        const res = await fetchData(allVariables);
        const values = res ? res.data.roles.anyRole.planningMetrics.variables : null;
        const stat = res ? res.data.roles.anyRole.planningProgress[0] : null;
        setStatus(stat);
        setData(values);
    };

    const onComplete = async (pawnId, deploymentId) => {
        if (!status || (status && !status.completed)) {
            addEvent(
                {
                    eventType: 'planningSurveyCompleted',
                    targetId: +pawnId,
                    targetType: 'pawn',
                    metadata: JSON.stringify({ deploymentId }),
                },
                { saveImmediately: true }
            );
        }

        await reloadData();
    };

    const onNewClose = async () => {
        setLoading(true);
        await reloadData();
        await props.loadData();
        setLoading(false);
    };

    const openAll = () => {
        setShowAll(true);
    };
    const closeAll = () => {
        if (showAll === true) {
            setShowAll(false);
        } else if (showAll === false) {
            setShowAll(null);
        } else if (showAll === null) {
            setShowAll(false);
        }
    };
    const completed = status && status.completed;

    return (
        <Loader loading={loading} removeChildren>
            {data && completed && (
                <>
                    <div className={style.topBar}>
                        {status && (
                            <div>
                                <strong>Planning Form Completed: </strong>
                                {format(status.completedDate, 'MM/DD/YYYY')}
                            </div>
                        )}
                        <div>
                            <button
                                type="button"
                                className={`btn btn-primary ${style.openButton} ${style.buttonColor}`}
                                onClick={openAll}
                            >
                                Open All <ArrowClosed />
                            </button>
                            <button
                                type="button"
                                className={`btn btn-primary ${style.closeButton} ${style.buttonColor}`}
                                onClick={closeAll}
                            >
                                Close All <ArrowOpen />
                            </button>
                            <button
                                type="button"
                                className={`btn btn-primary ${style.buttonColor}`}
                                onClick={download}
                            >
                                Download PDF
                            </button>
                        </div>
                    </div>
                    <div className={style.pageWrapper}>
                        <Report
                            width="100%"
                            showPagination={false}
                            onRef={element => setReport(element)}
                            downloadFilename={`GoalSettingPlan_${
                                selectedPerson ? selectedPerson.label : ''
                            }_${format(status.completedDate, 'MM/DD/YYYY')}`}
                        >
                            {filteredGoals.map((goal, i) => {
                                const goalDate = format(
                                    props.timestamps[goal.timestamp],
                                    'MM/DD/YYYY'
                                );
                                return (
                                    <Page>
                                        <PrintOnly>
                                            {i === 0 && (
                                                <>
                                                    <h2
                                                        style={{
                                                            fontFamily:
                                                                "'Barlow Condensed', sans-serif",
                                                        }}
                                                    >
                                                        Goal Setting Plan
                                                    </h2>
                                                    <div
                                                        style={{
                                                            marginBottom: '1em',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <div>
                                                            {' '}
                                                            <strong>
                                                                Planning Form Completed:{' '}
                                                            </strong>
                                                            {format(
                                                                status.completedDate,
                                                                'MM/DD/YYYY'
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            Page {i + 1} of {filteredGoals.length}{' '}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </PrintOnly>
                                        <PrintOnly>
                                            {i !== 0 && (
                                                <div
                                                    style={{
                                                        textAlign: 'right',
                                                        marginBottom: '1em',
                                                    }}
                                                >
                                                    Page {i + 1} of {filteredGoals.length}{' '}
                                                </div>
                                            )}
                                        </PrintOnly>
                                        <div key={goal.id}>
                                            <goal.component
                                                label={goal.name}
                                                timestamp={
                                                    goalDate !== 'Invalid Date' ? goalDate : null
                                                }
                                                show={showAll}
                                                index={i}
                                                variables={goal.variables}
                                                {...goal.props}
                                                data={data}
                                                strategy={goal.strategy ? goal.strategy : null}
                                            />
                                        </div>
                                        {i === filteredGoals.length - 1 && (
                                            <div style={{ marginTop: '1em' }}>
                                                <div>
                                                    <strong>Completed By:</strong>{' '}
                                                    {completedBy && completedBy}
                                                </div>
                                            </div>
                                        )}
                                    </Page>
                                );
                            })}
                        </Report>
                    </div>{' '}
                </>
            )}
            <PlanningSurvey
                {...props}
                status={status}
                requiredTypes={requiredTypes}
                onComplete={onComplete}
                onNewClose={onNewClose}
            />
        </Loader>
    );
};

InterventionPlanning.propTypes = {
    selectedPerson: PropTypes.object,
    goals: PropTypes.object,
    onComplete: PropTypes.func,
    loadData: PropTypes.func,
    noDevTags: PropTypes.bool,
    timestamps: PropTypes.object,
};

export default InterventionPlanning;
