import React, { useState, useEffect } from 'react';
import style from './style.css';
import ArrowClosed from 'cccisd-icons/arrow-right20';
import ArrowOpen from 'cccisd-icons/arrow-down20';
import { BrowserOnly, PrintOnly } from 'cccisd-laravel-report';

const GoalItem = props => {
    const { index, label, color, data, section, strategy, variables, show } = props;
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (show) {
            setOpen(true);
        } else if (show === false) {
            setOpen(false);
        } else if (show === null) {
            setOpen(false);
        }
    }, [show]);

    const colors = {
        '#8B76BE': { text: '#3E3554', background: '#E8E4F2' },
        '#E78C42': { text: '#774923', background: '#FAE8D9' },
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const colorSet = colors[color];
    const strategies = strategy(data);

    const taskVar = variables.find(item => item.includes('_task'));
    const describeVar = variables.find(item => item.includes('_describe'));
    const resourceVar = variables.find(item => item.includes('_resources'));
    const timeVar = variables.find(item => item.includes('_time'));
    const evidenceVar = variables.find(item => item.includes('_evidence'));

    const topRow = [
        { variable: taskVar, question: 'What needs to be done?', label: 'TASK' },
        {
            variable: describeVar,
            question: 'How will this task get done?',
            label: 'DESCRIPTION OF PLAN',
            reportLabel: 'DESCRIPTION',
        },
        { variable: resourceVar, question: 'What is needed to get it done?', label: 'RESOURCE' },
        { variable: timeVar, question: 'When will it be completed?', label: 'TIMELINE' },
    ];

    const stratArr = {
        'Avoiding Power Struggles': 'https://www.doublecheckcoaching.org/avoiding-power-struggles/',
        'Building a Classroom Community':
            'https://www.doublecheckcoaching.org/building-a-classroom-community/',
        'Charting Tool':
            'https://www.doublecheckcoaching.org/charting-tool-for-building-authentic-relationships/',
        'Code Switching': 'https://www.doublecheckcoaching.org/code-switching/',
        'Cooperative Learning': 'https://www.doublecheckcoaching.org/cooperative-learning/',
        'Cultural Relevance in Content':
            'https://www.doublecheckcoaching.org/cultural-relevance-in-content/',
        'Culturally Responsive FBT':
            'https://www.doublecheckcoaching.org/culturally-responsive-function-based-thinking/',
        'Defining and Teaching Classroom Rules':
            'https://www.doublecheckcoaching.org/defining-and-teaching-classroom-rules-and-routines/',
        'Developing and Using Clear Academic Objectives':
            'https://www.doublecheckcoaching.org/developing-and-using-clear-academic-objectives/',
        'Greeting Students at the Door':
            'https://www.doublecheckcoaching.org/greeting-students-at-the-door/',
        'Identifying Reinforcers':
            'https://www.doublecheckcoaching.org/identifying-reinforcers-for-the-classroom/',
        'Increasing Opportunities to Respond':
            'https://www.doublecheckcoaching.org/increasing-opportunities-to-respond/',
        'Interest Survey': 'https://www.doublecheckcoaching.org/student-interest-survey/',
        'Posting and Using a Schedule':
            'https://www.doublecheckcoaching.org/posting-and-using-a-schedule/',
        'Providing Academic Feedback':
            'https://www.doublecheckcoaching.org/providing-academic-feedback/',
        'Teaching Behavior Expectations':
            'https://www.doublecheckcoaching.org/teaching-behavior-expectations/',
        'Teaching Classroom Routines':
            'https://www.doublecheckcoaching.org/defining-and-teaching-classroom-rules-and-routines/',
        'Using Precorrection': 'https://www.doublecheckcoaching.org/using-precorrection/',
        'Using Active Supervision': 'https://www.doublecheckcoaching.org/using-active-supervision/',
        'Using Behavior-specific Praise':
            'https://www.doublecheckcoaching.org/using-behavior-specific-praise/',
        'Using Group Contingencies':
            'https://www.doublecheckcoaching.org/using-group-contingencies/',
        'Using Journals':
            'https://www.doublecheckcoaching.org/using-journals-to-build-authentic-relationships/',
        'Using Planned Ignoring': 'https://www.doublecheckcoaching.org/using-planned-ignoring/',
        'Using Social and Emotional Coaching':
            'https://www.doublecheckcoaching.org/using-social-and-emotional-coaching/',
        'Using an Attention Signal':
            'https://www.doublecheckcoaching.org/using-an-attention-signal/',
    };

    return (
        <>
            <div
                className={`${style.goalItem} ${open ? style.open : style.closed}`}
                style={{ backgroundColor: color, color: '#fff' }}
            >
                <div className={style.leftSide}>
                    <h4>
                        <strong>Goal {index + 1}:</strong> {label} |<i>{section}</i>
                    </h4>
                    <div className={style.strategyLabel}>
                        <strong
                            style={{
                                color: colorSet.background,
                                float: 'left',
                                marginRight: '1em',
                            }}
                        >
                            Strategy(ies) selected:
                        </strong>
                        {strategies.map(item => {
                            return (
                                <a
                                    className={style.stratLink}
                                    href={stratArr[item]}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <div
                                        key={item}
                                        className={style.strategyTag}
                                        style={{
                                            backgroundColor: colorSet.background,
                                            color: colorSet.text,
                                        }}
                                    >
                                        <span style={{ whiteSpace: 'nowrap' }}>{item}</span>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                </div>
                <div
                    className={style.rightSide}
                    onClick={() => {
                        handleClick();
                    }}
                >
                    <div className={style.date}>
                        <strong>Date Selected: </strong>
                        {props.timestamp}
                    </div>
                    {open ? <ArrowOpen /> : <ArrowClosed />}
                </div>
            </div>
            {open ? (
                <div className={style.detail}>
                    <div className={style.detailWrapper}>
                        <div className={style.mainQuestion}>
                            What actions will you take to meet this goal?
                        </div>
                        <div className={style.topRow}>
                            {topRow.map(item => {
                                return (
                                    <div className={style.topRowItem}>
                                        <h4>
                                            {item.reportLabel ? (
                                                <>
                                                    <BrowserOnly>{item.label}</BrowserOnly>
                                                    <PrintOnly>{item.reportLabel}</PrintOnly>
                                                </>
                                            ) : (
                                                item.label
                                            )}
                                        </h4>
                                        <div className={style.questionText}>{item.question}</div>
                                        <div className={style.answerText}>
                                            {data[item.variable]}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={style.bottomSection}>
                            <div className={style.mainQuestion}>
                                What evidence is needed to show that this intervention has been met?
                            </div>
                            <div className={style.answerText}>{data[evidenceVar]}</div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default GoalItem;
