import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import style from './style.css';
import { Formik, Form, Field, CccisdTextarea } from 'cccisd-formik';
import Pencil from 'cccisd-icons/pencil';

const Notes = props => {
    const notes = React.createRef();

    const hasValue = props.initialValue !== '';

    return (
        <div>
            <Modal
                title={`${props.label} Notes`}
                trigger={
                    <button
                        className={`btn ${hasValue ? style.pencilButtonNote : style.pencilButton}`}
                        type="button"
                    >
                        <Pencil />
                    </button>
                }
                ref={notes}
            >
                <Formik
                    initialValues={{
                        notes: props.initialValue,
                    }}
                    onSubmit={values => {
                        props.onSubmit(values, props.label, props.devTag);
                        notes.current.close();
                    }}
                    render={() => (
                        <Form>
                            <Field name="notes" component={CccisdTextarea} label="" />
                            <button className="btn btn-primary" type="submit">
                                Save
                            </button>
                        </Form>
                    )}
                />
            </Modal>
        </div>
    );
};

Notes.propTypes = {
    onSubmit: PropTypes.func,
    label: PropTypes.string,
    devTag: PropTypes.string,
    initialValue: PropTypes.string,
};

export default Notes;
