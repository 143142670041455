import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';
const StaticSlider = props => {
    const { scale, mark, minText, maxText } = props;

    return (
        <div className={style.sliderWrapper}>
            <div className={style.scale}>
                {scale.map(item => {
                    return (
                        <div
                            key={item}
                            className={`${style.scaleItem}  ${item === mark ? style.marked : ''}`}
                        >
                            {item}
                        </div>
                    );
                })}
            </div>
            <div className={style.textScale}>
                <div>{minText}</div>
                <div>{maxText}</div>
            </div>
        </div>
    );
};
StaticSlider.propTypes = {
    scale: PropTypes.array,
    mark: PropTypes.number,
    minText: PropTypes.string,
    maxText: PropTypes.string,
};
export default StaticSlider;
