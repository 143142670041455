import React from 'react';
import Modal from 'cccisd-modal';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import style from './style.css';
import PropTypes from 'prop-types';
import { addEvent } from 'cccisd-laravel-eventlog';
import Info from 'cccisd-icons/info2';
import Tooltip from 'cccisd-tooltip';
const PlanningSurvey = props => {
    const modal = React.createRef();
    const { selectedPerson, noDevTags } = props;

    const openModal = () => {
        modal.current.open();
    };
    const closeModal = () => {
        modal.current.close();
    };
    const goalsSelected = () => {
        const { goals } = props;
        let arr = Object.keys(goals).map(key => {
            return goals[key];
        });

        if (arr.some(item => item === true)) {
            return true;
        }
        return false;
    };
    const getFlowPropVars = () => {
        const {
            ConnectionGoal,
            AuthenticRelationshipsGoal,
            ReflectiveThinkingGoal,
            EffectiveCommunicationGoal,
            SensitivityGoal,
            StudentEngagementGoal,
            PacingInstructionGoal,
            DisruptiveBehaviorGoal,
            ClearExpectationsGoal,
            PraiseGoal,
            ReprimandsGoal,
            SmoothTransitionsGoal,
            PBSCCOtherGoal,
        } = props.goals;

        return {
            ConnectionGoal: ConnectionGoal ? '1' : null,
            AuthenticRelationshipsGoal: AuthenticRelationshipsGoal ? '1' : null,
            ReflectiveThinkingGoal: ReflectiveThinkingGoal ? '1' : null,
            EffectiveCommunicationGoal: EffectiveCommunicationGoal ? '1' : null,
            SensitivityGoal: SensitivityGoal ? '1' : null,
            StudentEngagementGoal: StudentEngagementGoal ? '1' : null,
            PacingInstructionGoal: PacingInstructionGoal ? '1' : null,
            DisruptiveBehaviorGoal: DisruptiveBehaviorGoal ? '1' : null,
            ClearExpectationsGoal: ClearExpectationsGoal ? '1' : null,
            PraiseGoal: PraiseGoal ? '1' : null,
            ReprimandsGoal: ReprimandsGoal ? '1' : null,
            SmoothTransitionsGoal: SmoothTransitionsGoal ? '1' : null,
            PBSCCOtherGoal: PBSCCOtherGoal ? '1' : null,
        };
    };
    const handleSubmit = async () => {
        await props.onComplete(selectedPerson.value, selectedPerson.deploymentId);
    };
    const saveStart = () => {
        if (!props.status) {
            addEvent(
                {
                    eventType: 'planningSurveyStarted',
                    targetId: selectedPerson.value,
                    targetType: 'pawn',
                    metadata: JSON.stringify({
                        deploymentId: selectedPerson.deploymentId,
                    }),
                },
                { saveImmediately: true }
            );
        }
    };
    const renderSurveyModal = buttonText => {
        const surveyList = selectedPerson.role === 'coach' ? ['survey25'] : ['survey40'];
        const hasGoals = goalsSelected();

        return (
            <>
                {!hasGoals && buttonText === 'Edit Planning Form' && (
                    <Tooltip
                        title="<p>The Goal Setting Planning form cannot be edited if there are no goals selected on the Check-Up Feedback tab</p>"
                        size="medium"
                        placement="bottom"
                    >
                        <div style={{ fontWeight: 'bold', marginTop: '35px', marginRight: '1em' }}>
                            <sup>
                                <Info />
                            </sup>{' '}
                            No goals selected
                        </div>
                    </Tooltip>
                )}
                <button
                    type="button"
                    className={`btn btn-default ${style.buttonStyle}`}
                    disabled={!hasGoals || !props.requiredTypes}
                    onClick={openModal}
                >
                    {buttonText}
                </button>
                <Modal
                    ref={modal}
                    size="large"
                    title="Goal Setting Plan"
                    beforeClose={props.onNewClose}
                    beforeShow={saveStart}
                >
                    <div className={style.fixContainer}>
                        <DeploymentPlayer
                            onComplete={() => {
                                handleSubmit();
                                closeModal();
                            }}
                            deploymentId={selectedPerson.deploymentId}
                            pawnId={+selectedPerson.value}
                            pawnHash={selectedPerson.pawn.pawnHash}
                            assignmentOptions={surveyList}
                            flowProps={{
                                hidePlayButton: true,
                                variables: getFlowPropVars(),
                            }}
                        />
                    </div>
                </Modal>
            </>
        );
    };

    const renderInitialCard = () => {
        const hasGoals = goalsSelected();

        const noProgressText = noDevTags ? (
            <p>
                The Check-Up Feedback Form has not been completed yet. You may not start a Planning
                Form until a Feedback Form has been completed.
            </p>
        ) : (
            <p>
                {hasGoals ? (
                    <div>
                        {props.requiredTypes
                            ? 'The Goal Setting Planning Form has not been completed yet. Click the Start Planning Form button below to begin. Your responses will display here once this survey has been submitted.'
                            : 'You must select at least one goal from the CARES section and at least one goal from the Positive Behavior Supports & Classroom Climate section to start.'}
                    </div>
                ) : (
                    <div>
                        The Goal Setting Planning form cannot be started until goals have been
                        selected on the Check-Up Feedback tab.
                    </div>
                )}
            </p>
        );

        const progressText = (
            <p>
                {hasGoals ? (
                    <span>
                        {props.requiredTypes
                            ? 'The Goal Setting Planning Form has been started but has not been completed yet. Click the Resume Planning Form button below to resume. Your responses will display here once this survey has been submitted.'
                            : 'You must select at least one goal from the CARES section and at least one goal from the Positive Behavior Supports & Classroom Climate section to resume.'}
                    </span>
                ) : (
                    <span>
                        There are no goals selected on the Check-Up Feedback tab. You cannot resume
                        the Goal Setting Planning Form until one or more goals are selected.
                    </span>
                )}
            </p>
        );

        const buttonText = !props.status ? 'Start Planning Form' : 'Resume Planning Form';
        return (
            <div className={style.card}>
                {!props.status ? noProgressText : progressText}

                <div className={style.modalBox}>{renderSurveyModal(buttonText)}</div>
            </div>
        );
    };

    const renderEditButton = () => {
        // const { noDevTags } = props;
        return <div className={style.buttonOnly}>{renderSurveyModal('Edit Planning Form')}</div>;
    };

    const inProgress = props.status && props.status.started && !props.status.completed;
    // If no planning form completed
    return (
        <>
            {(!props.status || inProgress) && renderInitialCard()}

            {props.status && props.status.completed && renderEditButton()}

            <hr />
        </>
    );
};

PlanningSurvey.propTypes = {
    selectedPerson: PropTypes.object,
    metrics: PropTypes.object,
    goals: PropTypes.object,
    onComplete: PropTypes.func,
    onNewClose: PropTypes.func,
    noDevTags: PropTypes.bool,
    requiredTypes: PropTypes.bool,
};

export default PlanningSurvey;
